import React, { Suspense, lazy, useState } from "react";
import { Router, Route, Switch, Redirect } from "react-router";
import { createHashHistory } from "history";
import { Layout, BackTop } from "antd";
import Loading from "../component/Loading";
import HeaderNav from "./HeaderNav";
import utils from "../component/utils";
import "./footer.css";
import TopBackground from "../component/TopBackground/TopBackground";

const { Header, Content, Footer } = Layout;

const Home = lazy(() => import("../pages/Home/Home"));
const Browse = lazy(() => import("../pages/Browse/Browse"));
const Prediction = lazy(() => import("../pages/Prediction/Prediction"));
const Search = lazy(() => import("../pages/Search/Search"));
const Help = lazy(() => import("../pages/Help/Help"));
const Download = lazy(() => import("../pages/Download/Download"));
// const AgingAndDiseases = lazy(() =>
//   import("../pages/AgingAndDiseases/AgingAndDiseases")
// );
const AgingDetail = lazy(() => import("../pages/AgingDetail/AgingDetail"));

export default function RPSRouter() {
  const history = createHashHistory();

  const [isHome, updateIsHome] = useState(true);

  React.useEffect(() => {
    utils.redirectToHttp();
    updateIsHome(utils.isHome()); // 加载页面时先执行一次判断是否在home页
    const unlisten = history.listen((location, action) => {
      // 监听路由
      updateIsHome(utils.isHome()); //路由改变时执行判断是否在首页
      window.scrollTo(0, 0); // 路由改变时先滚动至最上方
    });
    return () => {
      unlisten();
    };
  }, []);

  return (
    <React.Fragment>
      <Layout style={{ backgroundColor: "#fff" }}>
        {!isHome && <TopBackground />}
        <Suspense fallback={<Loading />}>
          <Router history={history}>
            <HeaderNav />
            <Content
              style={
                isHome
                  ? {}
                  : {
                      /* width: "88%", */ margin: "auto",
                      /* maxWidth: "1400px", */ minHeight: "90vh",
                      paddingBottom: "50px",
                    }
              }
            >
              <BackTop />
              <Switch>
                <Route path="/Home" component={Home} />
                <Route path="/Search/:type?/:keyWords?" component={Search} />
                <Route path="/Browse" component={Browse} />
                <Route path="/Prediction" component={Prediction} />
                {/* <Route
                  path="/AgingAndSenescence"
                  component={AgingAndDiseases}
                /> */}
                <Route
                  path="/AgingDetail/:ncbSenescenceId"
                  component={AgingDetail}
                />
                <Route path="/Download" component={Download} />
                <Route path="/Help" component={Help} />
                <Redirect to="/Home" />
              </Switch>
            </Content>
            <Footer
              style={{ display: isHome ? "none" : "block" }}
              className="rps-footer"
            >
              NuCo-Base.
            </Footer>
          </Router>
        </Suspense>
      </Layout>
    </React.Fragment>
  );
}
