import { LoadingOutlined } from "@ant-design/icons";

export default function Loading(props) {
  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "100px",
        marginBottom: "100px",
        position: "relative",
      }}
    >
      <div style={{ fontSize: "25px" }}>Loading...</div>
      <LoadingOutlined style={{ fontSize: 24 }} spin />
    </div>
  );
}
